import axios from '@axios'

export default {
  namespaced: true,
  state: {
    contacts: [],
  },
  getters: {},
  mutations: {
    GET_CONTACT(state, data) {
      state.contacts = data
    },
  },
  actions: {
    async getContactData({ commit }) {
      const res = await axios.get('http://localhost:3000/contact')
      commit('GET_CONTACT', res.data)
    },
    addCat(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://localhost:3000/contact', data)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
