<template>
  <div>
    <div>
      <div class="m-2">
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            />
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  v-b-modal="'modal-add'"
                  variant="primary"
                >
                  <span class="text-nowrap">Add Contact</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col
            v-for="(v, i) in contactList"
            :key="i"
          >
            <b-card
              :title="v.title"
              cols="4"
            >
              <hr>
              <b-card-text>
                {{ v.subtitle }}
              </b-card-text>
              <b-card-text>
                - {{ v.location }}
              </b-card-text>
              <b-card-text>
                <feather-icon icon="PhoneIcon" /> {{ v.phone }}
              </b-card-text>
              <b-card-text>
                <feather-icon icon="SmartphoneIcon" /> {{ v.office_number }}
              </b-card-text>
              <b-card-text>
                <feather-icon icon="MailIcon" /> {{ v.email }}
              </b-card-text>
              <b-card-text>
                <feather-icon icon="GlobeIcon" /> {{ v.website }}
              </b-card-text>
              <b-card-text>
                <div>
                  <span
                    v-if="v.language"
                  >
                    <b-badge
                      variant="dark"
                      class="my-2"
                    >{{ v.language.name }}</b-badge>
                  </span>
                </div>
              </b-card-text>
              <hr>
              <template>
                <b-button
                  v-b-modal="'modal-edit'"
                  class="m-1"
                  variant="primary"
                  @click="getContact(v.id)"
                >
                  <feather-icon icon="EditIcon" />
                  <span> Edit </span>
                </b-button>
                <b-button @click="deleteContact(v.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-button>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal
      v-if="show"
      id="modal-edit"
      centered
      title="Edit Contact"
      hide-footer
    >
      <b-form
        ref="myForm"
        class="p-2"
        @submit.enter.prevent=""
      >
        <b-form-group
          label="Name"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="editData.title"
            placeholder="Name"
          />
        </b-form-group>
        <b-form-group
          label="Sub Title"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="editData.subtitle"
            placeholder="Sub Title"
          />
        </b-form-group>
        <b-form-group
          label="location"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="editData.location"
            placeholder="location"
          />
        </b-form-group>
        <b-form-group
          label="Phone"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="editData.phone"
            placeholder="Phone"
          />
        </b-form-group>
        <b-form-group
          label="Other Phone"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="editData.office_number"
            placeholder="Other Phone"
          />
        </b-form-group>
        <b-form-group
          label="Mail"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="editData.email"
            type="email"
            placeholder="Mail"
          />
        </b-form-group>
        <b-form-group
          label="Website"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="editData.website"
            placeholder="Website"
          />
        </b-form-group>
        <select-language :selected-language="editData" />
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            @click="updateCat(editData.id)"
          >
            save
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      v-if="show"
      id="modal-add"
      centered
      title="Add Contact"
      hide-footer
    >
      <b-form
        ref="myForm"
        class="p-2"
        @submit.enter.prevent=""
      >
        <b-form-group
          label="Name"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="addData.title"
            placeholder="Name"
          />
        </b-form-group>
        <b-form-group
          label="Sub Title"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="addData.subtitle"
            placeholder="Sub Title"
          />
        </b-form-group>
        <b-form-group
          label="location"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="addData.location"
            placeholder="location"
          />
        </b-form-group>
        <b-form-group
          label="Phone"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="addData.phone"
            placeholder="Phone"
          />
        </b-form-group>
        <b-form-group
          label="Other Phone"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="addData.office_number"
            placeholder="Other Phone"
          />
        </b-form-group>
        <b-form-group
          label="Work Hours"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="addData.work_hours"
            placeholder="Other Phone"
          />
        </b-form-group>
        <b-form-group
          label="Mail"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="addData.email"
            type="email"
            placeholder="Mail"
          />
        </b-form-group>
        <b-form-group
          label="Website"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="addData.website"
            placeholder="Website"
          />
        </b-form-group>
        <b-form-group
          id="fileInput"
        >
          <b-form-file
            accept="image/jpeg, image/png, image/gif"
            placeholder="Drag&amp;drop or select add Image"
            @change="onChange"
          />
        </b-form-group>
        <select-language :selected-language="addData" />
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            @click="addContact()"
          >
            save
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, onUnmounted,
} from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BImg, BFormFile, BCardText, BMediaBody, BMediaAside, BBadge,
  BDropdown, BDropdownItem, BPagination, BFormGroup, BFormInvalidFeedback, BFormTextarea, BFormSelect, VBModal, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import store from '@/store'
import contactModule from '../ContactModule'
import handleFormData from '@/common/compositions/handleFormData'
import selectLanguage from '@/common/components/SelectLanguage.vue'

Vue.use(VueSweetalert2)

export default {
  components: {
    BCard,
    BBadge,
    BMediaAside,
    selectLanguage,
    BCardText,
    BMediaBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BForm,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      editData: {},
      show: true,
      contactList: [],
      addData: {
        title: '',
        subtitle: '',
        image: '',
        location: '',
        email: '',
        website: '',
        phone: '',
        office_number: '',
        work_hours: '',
        language_id: '',
      },
    }
  },
  setup() {
    const CONTACT_DATA_MODULE = 'contactModule'
    // const stor = useStore

    // Register module
    if (!store.hasModule(CONTACT_DATA_MODULE)) store.registerModule(CONTACT_DATA_MODULE, contactModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTACT_DATA_MODULE)) store.unregisterModule(CONTACT_DATA_MODULE)
    })

    const { formData, setFormData } = handleFormData()

    return {
      formData, setFormData,
    }
  },
  created() {
    this.getContactList()
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.addData.image = e.target.files[0]
    },
    async getContactList() {
      await axios.get('get/contact').then(res => {
        this.contactList = res.data.success.data
      })
    },
    getContact(id) {
      axios.get(`get/contact/${id}`).then(res => {
        this.editData = res.data.success
      })
    },
    updateCat(id) {
      axios.post(`edit/contact/${id}`, this.editData).then(res => {
        if (res.status === 200) {
          this.getContactList()
          this.$bvModal.hide('modal-edit')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Edited Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.editData = {}
        }
      })
    },
    deleteContact(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios.delete(`delete/contact/${id}`).then(res => {
            if (res.status === 200) {
              this.getContactList()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Deleted Succesfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
            }
          })
        }
      })
    },
    addContact() {
      this.setFormData(this.addData)
      axios.post('add/contact', this.formData).then(res => {
        if (res.status === 200) {
          this.getContactList()
          this.$bvModal.hide('modal-add')
          this.addData = {}
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Added Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
